import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import axios from './config/http'
import VueAxios from 'vue-axios'
// import Datepicker from 'vue3-date-time-picker';
// import 'vue3-date-time-picker/dist/main.css'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@/assets/css/fontawesome/css/font-awesome.min.css';
import locale from "@/mixins/locale"
import debounce from "@/config/debouncer"
import monetary from "@/filters/monetary";
// createApp(App).mount('#app')
import Vue3Editor from 'vue3-editor'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faUser,
    faStore,
    faChartColumn,
    faBuildingCircleArrowRight,
    faDownload,
    faClipboard,
    faPencil,
    faTrashCan,
    faMagnifyingGlass,
    faUpLong,
    faDownLong,
    faGrip,
    faListUl,
    faPhone,
    faEnvelope,
    faBuilding,
    faArrowRightArrowLeft,
    faCommentDots,
    faBarsProgress,
    faPlus,
    faCheck,
    faShoppingBag,
    faFile,
    faUserTie,
    faXmark,
    faMinus,
    faSubscript,
} from "@fortawesome/free-solid-svg-icons";

library.add(faPlus);
library.add(faGrip);
library.add(faSubscript);
library.add(faXmark);
library.add(faFile);
library.add(faMinus);
library.add(faCheck);
library.add(faShoppingBag);
library.add(faUser);
library.add(faPhone);
library.add(faStore);
library.add(faUpLong);
library.add(faListUl);
library.add(faPencil);
library.add(faUserTie);
library.add(faDownLong);
library.add(faEnvelope);
library.add(faBuilding);
library.add(faDownload);
library.add(faTrashCan);
library.add(faClipboard);
library.add(faChartColumn);
library.add(faCommentDots);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faBarsProgress);
library.add(faMagnifyingGlass);
library.add(faArrowRightArrowLeft);
library.add(faBuildingCircleArrowRight);

createApp(App)
    // .filter('monetary', monetary)
    .directive('debounce', (el,binding) => debounce(el,binding))
    .mixin(locale)
    .use(store)
    .use(router)
    .use(Vue3Editor)
    .use(VueAxios, axios)
    // .component('Datepicker', Datepicker)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('QuillEditor', QuillEditor).mount('#app')
