<template>
  <div id="sidebar"
       class="fixed w-16 md:w-24 p-4 bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 m-5 rounded-xl shadow mt-28 bg-blur z-40"
  >
    <div :class="`flex items-start justify-between ${open ? 'border-b border-gray-300 pb-3' : ''}`">
<!--      <new-logo id="sidebar-icon" class="w-10 h-10"/>-->
      <div class="w-full flex items-center justify-center">
        <div
            @click="open=!open; $emit('isShowSidebar', open);"
            class="bg-white w-6 h-6 flex justify-center items-center text-center align-middle
             rounded-full mt-2 cursor-pointer text-gray-600 hover:text-base-500 font-black">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="text-sm font-bold" :class="open ? 'transform rotate-180' : ''" />
          <!--        <component :is="open? 'chevron-left-icon' : 'chevron-right-icon'"/>-->
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div id="categories-list" class="flex flex-col pb-4 space-y-2 overflow-y-auto">
        <div v-for="item in navigation" :key="item.name">
          <router-link
              :to="item.url"
              :class="`flex ${open? 'justify-start' : 'justify-center'} space-x-1.5 px-1.5 cursor-pointer hover:text-base-600 ${$route.path == item.url ? 'text-base-500 bg-base-transparent_500' : 'text-gray-600'} hover:bg-white rounded-xl items-center mr-1`"
          >
            <div class="text-2xl">
              <font-awesome-icon v-if="item.icon" :icon="`fa-solid fa-${item.icon}`" />
<!--              <i v-if="item.icon" :class="`fa fa-${item.icon}`" aria-hidden="true"></i>-->
              <i v-else class="fa fa-arrows-h" aria-hidden="true"></i>
            </div>
            <p v-if="open">{{ item.name }}</p>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newLogo from "@/components/ui/NewLogo";
// import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/solid"
// import {ArrowLeftIcon} from "@heroicons/vue/outline"
import gsap from "gsap";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    newLogo,
    // ChevronLeftIcon,
    // ChevronRightIcon
  },
  name: "sidebar",
  data() {
    return {
      open: false,
      navigation: [
        {name: "Analitika", icon: "chart-column", url: "/"},
        {name: "Provajderi", icon: "store", url: "/providers"},
        {name: "Kompanije", icon: "building", url: "/companies"},
        // {name: "Admins", icon: "", url: "/admins"},
        // {name: "Suggested benefits", icon: "", url: "/suggestions"},
        {name: "Kupovine", icon: "arrow-right-arrow-left", url: "/invoices"},
        {name: "Sugestije", icon: "comment-dots", url: "/suggestions"},
        {name: "Pretplatnici", icon: "subscript", url: "/subscribers"},
        {name: "Jobs", icon: "bars-progress", url: "/jobs"},
        {name: "Rezervacije", icon: "envelope", url: "/bookings"},
        // {name: "Jobs", icon: "", url: "/jobs"},
        // {name: "Profile", icon: "", url: "/profile"}
      ]
    }
  },
  computed: {
    ...mapGetters('filters', ['current_category_id'])
  },
  watch: {
    open: function (val) {
      // this.$emit('onToggle', val);
      let screen_w = window.screen.width
      if (val) {
        let sidebar_w = 280

        if (screen_w < 400) {
          sidebar_w = screen_w - 46
        }

        gsap.to("#sidebar", {width: sidebar_w + 'px', ease: 'elastic', duration: 1.5})
        // gsap.to("#sidebar-icon", {scale: '1.2', ease: 'back', duration: 0.4})
      } else {
        // if (screen_w < 769) gsap.to("#sidebar", {width: '68px', ease: 'back', duration: 0.4});
        if (screen_w < 769) gsap.to("#sidebar", {width: '64px', ease: 'back', duration: 0.4});
        else gsap.to("#sidebar", {width: '96px', ease: 'back', duration: 0.4})
      }
    }
  },
  methods: {

  },
  async mounted() {
    this.$store.dispatch('filters/fetch_categories')
  }
}
</script>

<style scoped>
#sidebar {
  height: calc(100vh - 140px);
  overflow: hidden;
}

#categories-list {
  height: calc(100vh - 267px);
}

.z-1000 {
  z-index: 10000;
}

/*
 *  STYLE 7
 */

#categories-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 4px;
}

#categories-list::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

#categories-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-image: -webkit-gradient(linear,
  left bottom,
  left top,
  color-stop(0.44, theme('colors.base.500')),
  color-stop(0.72, theme('colors.base.600')),
  color-stop(0.86, theme('colors.base.700')));
}
</style>